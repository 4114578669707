import React from 'react';
import './Footer.css';
import logo from '../assets/logo2.png';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Company Info Section */}
        <div className="footer-section">
          <div className="company-info">
            <h2 className="company-logo">
              <img src={logo} alt="Opdrape logo" className='logood'/>
            </h2>
            <p className="company-description">
              Opdrape Fashion Ltd. providing elegance & lucrative outfit items sourced both locally 
              & globally. Proudly Made in Bangladesh.
            </p>
            <div className="social-links">
              <a href="#instagram" aria-label="Instagram">
                <a href="https://www.instagram.com/opdrape/"><i className="fab fa-instagram"></i></a>
                
              </a>
              <a href="#twitter" aria-label="Twitter">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#youtube" aria-label="YouTube">
                <i className="fab fa-youtube"></i>
              </a>
              <a href="#facebook" aria-label="Facebook">
                <a href="https://www.facebook.com/opdrape"><i className="fab fa-facebook-f"></i>
              </a></a>
            </div>
          </div>
        </div>

        {/* Contact Info Section */}
        <div className="footer-section">
          <div className="contact-info">
            <div className="contact-item">
              <i className="far fa-envelope"></i>
              <span>info@opdrape.com</span>
            </div>
            <div className="contact-item">
              <i className="fas fa-phone"></i>
              <span>+880 1645272591</span>
            </div>
            <div className="contact-item">
            </div>
            <div className="contact-item">
              <i className="fas fa-map-marker-alt"></i>
              <div className="address">
                <p>Bou Bazaar ,Akran, Birulia</p>
                <p>Ashulia,Savar</p>
                <p>Dhaka-1216</p>
              </div>
            </div>
          </div>
        </div>

        {/* Services Section */}
        <div className="footer-section">
          <div className="services-links">
            <div className="services-column">
              <h3>Services & Help</h3>
              <ul>
                {/* <li><a href="#size-guide">Size Guide</a></li> */}
                {/* <li><a href="#gift-card">Gift Card & Offers</a></li> */}
                <li><a href="#exchange">Exchange Policy</a></li>
                {/* <li><a href="#shipping">Order, Payment, Shipping & Policies</a></li> */}
                <li><a href="#terms">Terms of Services & Conditions</a></li>
                {/* <li><a href="#privacy"></a></li> */}
                <li><a href="#about">About Us</a></li>
                <li><a href="#contact">Contact us</a></li>
                <li><a href="#career">Career</a></li>
              </ul>
            </div>
            <div className="services-column">
              {/* <h3>Quick Links</h3>
              <ul>
                <li><a href="#signin">Sign in</a></li>
                <li><a href="#tracking">Order Tracking</a></li>
                <li><a href="#wishlist">Wishlist</a></li>
              </ul> */}
            </div>
          </div>
          <button className="shop-outlets-btn">
            <i className="fas fa-store"></i> EASY SHOP OUTLETS
          </button>
        </div>
      </div>
    </footer>
  );
}

export default Footer; 