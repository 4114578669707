import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import ProductList from './components/ProductList';
// import ProductCatalog from './components/ProductCatalog';
import Cart from './components/Cart';
import Footer from './components/Footer';

function App() {
  const [cartItems, setCartItems] = useState([]);
  const [isCartOpen, setIsCartOpen] = useState(false);

  const addToCart = (product) => {
    setCartItems([...cartItems, product]);
  };

  const removeFromCart = (productId) => {
    setCartItems(cartItems.filter(item => item.id !== productId));
  };

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  return (
    <Router>
      <div className="App">
        <Navbar cartItemCount={cartItems.length} onCartClick={toggleCart} />
        <main className="main-content">
          <Routes>
            <Route path="/" element={
              <>
                <section className="new-arrivals-section">
                  <h1>NEW ARRIVALS</h1>
                  <ProductList addToCart={addToCart} />
                </section>
                <section className="collections-section">
                  <h2>OUR COLLECTIONS</h2>
                  {/* <ProductCatalog /> */}
                </section>
              </>
            } />
            {/* <Route path="/collections" element={<ProductCatalog />} /> */}
          </Routes>
        </main>
        {isCartOpen && (
          <Cart 
            items={cartItems} 
            onClose={toggleCart}
            removeFromCart={removeFromCart}
          />
        )}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
