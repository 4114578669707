import React, { useState } from 'react';
import './ProductList.css';
import hoodie from './hoodie.png'
import hoodie_2 from './hoodie_back.png'
import zoomed from './zoomed.png'


function ProductList({ addToCart }) {
  const [isZoomed, setIsZoomed] = useState(false);
  const [activeSection, setActiveSection] = useState('product');
  const product_des = "Premium Comfort: 250GSM fabric for a lightweight, cozy feel.\n\n" +
    "Unique Design: Inspired by Valorant & Arcane, blending fandom with style.\n\n" +
    "Durable Freshness: No-lint technology for a clean, polished look every time.";

  const handleZoomToggle = () => {
    setIsZoomed(!isZoomed);
  };

  const toggleSection = (section) => {
    setActiveSection(activeSection === section ? '' : section);
  };

  const products = [
    {
      id: 1,
      name: "Men's Casual Jacket",
      price: 1275,
      // discount: "50% OFF",
      modelInfo: "100% Cotton, Model Is 6'1\" And Wears Size M",
      // colors: ['#2C2C2C', '#8B4513', '#FFA07A'],
    }
  ];

  const [activeSlide, setActiveSlide] = useState(0);

  const previewImages = [
    {
      src: hoodie_2,
      alt: "Jacket Back View"
    },
    {
      src: zoomed,
      alt: "Zoomed View"
    },
    {
      src: hoodie,
      alt: "Front View"
    }
  ];

  return (
    <div className="product-layout">
      {/* Left Section */}
      <div className="product-left">
        <h1 className="product-heading">Men's<br />Casual<br />Hoodie</h1>
        <p className="product-subtext">{products[0].modelInfo}</p>
        
        {/* <div className="color-options">
          <span className="color-label">COLORS</span>
          <div className="color-dots">
            {/* {products[0].colors.map((color, index) => (
              <button 
                key={index}
                className="color-dot"
                style={{ backgroundColor: color }}
                aria-label={`Color option ${index + 1}`}
              />
            ))} */}
          {/* </div>
        </div>  */}

        <button className="add-to-cart">
          + Add to Cart
        </button>

        <div className="social-share">
          <button className="share-btn">
            <i className="fas fa-share"></i> Share
          </button>
          <button className="share-btn">
            <i className="fas fa-heart"></i> Tweet
          </button>
          <button className="share-btn">
            <i className="fas fa-thumbtack"></i> Pin it
          </button>
        </div>
      </div>

      {/* Center Section */}
      <div className="product-center">
        <div className={`image-container ${isZoomed ? 'zoomed' : ''}`}>
          <button 
            className="zoom-button"
            onClick={handleZoomToggle}
            aria-label={isZoomed ? 'Zoom out' : 'Zoom in'}
          >
            <i className={`fas ${isZoomed ? 'fa-search-minus' : 'fa-search-plus'}`}></i>
          </button>
          <div className="price-tag">
            <span className="price-amount"> ৳{products[0].price.toFixed(2)}</span>
            {/* <span className="discount-label">{products[0].discount}</span> */}
          </div>
          <div className="image-wrapper">
            <img 
              src={hoodie} 
              alt="Men's Casual Jacket" 
              className="product-image"
            />
          </div>
        </div>
      </div>

      {/* Right Section */}
      <div className="product-right">
        <div className="right-header">
          <div className="best-selling">
            <span>Best Selling</span>
            <i className="fas fa-arrow-right"></i>
          </div>
          {/* <div className="discount-badge">45% OFF</div> */}
        </div>

        <div className="product-preview">
          <div className="carousel-container">
            <div 
              className="carousel-track" 
              style={{ transform: `translateX(-${activeSlide * 100}%)` }}
            >
              {previewImages.map((image, index) => (
                <div key={index} className="carousel-slide">
                  <img src={image.src} alt={image.alt} />
                </div>
              ))}
            </div>
          </div>
          <h3>Men's Casual Jacket</h3>
          <div className="preview-nav">
            {previewImages.map((_, index) => (
              <button 
                key={index}
                className={`nav-dot ${activeSlide === index ? 'active' : ''}`}
                onClick={() => setActiveSlide(index)}
                aria-label={`View slide ${index + 1}`}
              />
            ))}
          </div>
        </div>

        <div className="product-accordion">
          <div className="accordion-item">
            <button 
              className={`accordion-header ${activeSection === 'product' ? 'active' : ''}`}
              onClick={() => toggleSection('product')}
            >
              <span>Product Info</span>
              <i className={`fas ${activeSection === 'product' ? 'fa-minus' : 'fa-plus'}`}></i>
            </button>
            {activeSection === 'product' && (
              <div className="accordion-content">
                <p>{product_des}</p>
              </div>
            )}
          </div>

          <div className="accordion-item">
            <button 
              className={`accordion-header ${activeSection === 'delivery' ? 'active' : ''}`}
              onClick={() => toggleSection('delivery')}
            >
              <span>Delivery Info</span>
              <i className={`fas ${activeSection === 'delivery' ? 'fa-minus' : 'fa-plus'}`}></i>
            </button>
            {activeSection === 'delivery' && (
              <div className="accordion-content">
                <div className="delivery-timeline">
                  <div className="timeline-item">
                    <div className="timeline-icon">
                      <i className="fas fa-box"></i>
                    </div>
                    <div className="timeline-content">
                      <h4>Order Placed</h4>
                      <p>Your order has been confirmed</p>
                    </div>
                  </div>
                  <div className="timeline-item">
                    <div className="timeline-icon">
                      <i className="fas fa-warehouse"></i>
                    </div>
                    <div className="timeline-content">
                      <h4>Processing</h4>
                      <p>Order is being prepared</p>
                    </div>
                  </div>
                  <div className="timeline-item">
                    <div className="timeline-icon">
                      <i className="fas fa-truck"></i>
                    </div>
                    <div className="timeline-content">
                      <h4>In Transit</h4>
                      <p>Package is on the way</p>
                    </div>
                  </div>
                  <div className="timeline-item">
                    <div className="timeline-icon">
                      <i className="fas fa-home"></i>
                    </div>
                    <div className="timeline-content">
                      <h4>Delivered</h4>
                      <p>Package arrives at destination</p>
                    </div>
                  </div>
                </div>
                <div className="delivery-info-footer">
                  <p>Estimated delivery time: 3-5 business days</p>
                  <p>Free shipping on orders over $700</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductList; 