import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from '../assets/logo.png';

function Navbar({ cartItemCount, onCartClick }) {
  return (
    <nav className="navbar">
      <div className="navbar-top">
        Free Shipping on Orders Over $100 | Shop Now
      </div>
      
      <div className="navbar-main">
        <div className="nav-left">
          <Link to="/new-arrivals">New Arrivals</Link>
          <Link to="/women">Women</Link>
          <Link to="/men">Men</Link>
          <Link to="/sale">Sale</Link>
          <Link to="/collections">Collections</Link>
        </div>

        <div className="nav-brand">
          <Link to="/"><img src={logo} alt="" className='lg' /></Link>
        </div>

        <div className="nav-right">
          <div className="search-container">
            <input
              type="text"
              className="search-input"
              placeholder="Search products..."
            />
            <button className="search-toggle">
              <i className="fas fa-search"></i>
            </button>
          </div>

          <Link to="/account" className="icon-link">
            <i className="far fa-user"></i>
            <span className="icon-label">Account</span>
          </Link>

          <Link to="/wishlist" className="icon-link">
            <i className="far fa-heart"></i>
            <span className="icon-label">Wishlist</span>
          </Link>
          
          <button className="cart-button" onClick={onCartClick}>
            <i className="fas fa-shopping-bag"></i>
            <span className="icon-label">Cart</span>
            {cartItemCount > 0 && (
              <span className="cart-count">{cartItemCount}</span>
            )}
          </button>
        </div>
      </div>
    </nav>
  );
}

export default Navbar; 